import axios from "../service/axios";
import { ENDPOINT_PATH } from "../app.constant";

const api = {
    get: {
        getDeviceListDetails: (payload) => axios.get(ENDPOINT_PATH.CONTRACT_DEVICES, payload)
    },
    post: {
        returnSignIn: (payload) => axios.post(ENDPOINT_PATH.RETURN_SIGNIN, payload),
        initiateOrder: (payload) => axios.post(ENDPOINT_PATH.INITIATE_ORDER, payload),
        requestOtp: (payload) => axios.post(ENDPOINT_PATH.REQUEST_OTP, payload),
        validateOtp: (payload) => axios.post(ENDPOINT_PATH.VALIDATE_OTP, payload),
        validateDevice: (payload) => axios.post(ENDPOINT_PATH.VALIDATE_DEVICE, payload),
        uploadDeviceImage: (payload) => axios.post(ENDPOINT_PATH.UPLOAD_DEVICE_IMAGE, payload),
        completeOrder: (payload) => axios.post(ENDPOINT_PATH.COMPLETE_ORDER, payload),
        contractValidate: (id, payload) => axios.post(`${ENDPOINT_PATH.CONTRACT_VALIDATION}/${id}`, payload),
        contractAccept: (id, payload) => axios.post(`${ENDPOINT_PATH.CONTRACT_SIGN}/${id}`, payload),
        customerCollection: (payload) => axios.post(ENDPOINT_PATH.CONTRACT, payload),
        contractDevicePlanPrice: (payload) => axios.post(ENDPOINT_PATH.CONTRACT_DEVICE_PLAN_PRICE, payload),
    },
};

export default api;
