import * as axs from "axios";

const axios = {
    get: async (url, params) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders();

        return axs.get(endPointUrl, { params, ...headers });
    },
    post: async (url, body) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders();

        return axs.post(endPointUrl, body, headers);
    },
};

const getHeaders = async () => {
    let headers = { "Content-Type": "application/json" };
    return headers;
};

const getEndPointUrl = (path) => {
    const endpoint = process.env.SEC_API_URL + path;
    return endpoint;
};

export default axios;
